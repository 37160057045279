var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between pb-1 align-items-center"},[_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('expenses'))+" ")])]),_c('b-dropdown',{staticClass:"m-md-2",attrs:{"id":"dropdown-1","variant":"primary","text":_vm.$t('new', { type: _vm.$t('expense') })}},[_c('b-dropdown-item',{attrs:{"to":{
            name: 'newExpense',
            params: { projectId: _vm.$route.params.projectId },
            query: { createType: 'direct_cost' },
          }}},[_vm._v(_vm._s(_vm.$t('directCost')))]),_c('b-dropdown-item',{attrs:{"to":{
            name: 'newExpense',
            params: { projectId: _vm.$route.params.projectId },
            query: { createType: 'additives_and_changes' },
          }}},[_vm._v(_vm._s(_vm.$t('additivesAndChanges')))])],1)],1)],1),_c('b-row',_vm._l((_vm.groupedExpenses),function(expense,index){return _c('b-col',{key:index,attrs:{"sm":"12"}},[_c('expenses-card',{attrs:{"expense":expense}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }