<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <div>
          <!-- <h1 class="font-weight-bolder">
            {{ $t('project') }} {{ project.name }}
          </h1> -->
          <h1>
            {{ $t('expenses') }}
          </h1>
        </div>
        <b-dropdown
          id="dropdown-1"
          variant="primary"
          :text="$t('new', { type: $t('expense') })"
          class="m-md-2"
        >
          <b-dropdown-item
            :to="{
              name: 'newExpense',
              params: { projectId: $route.params.projectId },
              query: { createType: 'direct_cost' },
            }"
          >{{ $t('directCost') }}</b-dropdown-item>
          <b-dropdown-item
            :to="{
              name: 'newExpense',
              params: { projectId: $route.params.projectId },
              query: { createType: 'additives_and_changes' },
            }"
          >{{ $t('additivesAndChanges') }}</b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- <expenses-filters class="mb-1" /> -->
    <b-row>
      <b-col
        v-for="(expense, index) in groupedExpenses"
        :key="index"
        sm="12"
      >
        <expenses-card :expense="expense" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ExpensesCard from '@/components/expenses/ExpensesCard.vue'
// import ExpensesFilters from '@/components/expenses/ExpensesFilters.vue'

export default {
  components: {
    ExpensesCard,
    // ExpensesFilters,
  },
  computed: {
    ...mapGetters('projects', ['project']),
    ...mapGetters('groupedExpenses', ['groupedExpenses']),

    totalIncome() {
      const total = this.projectIncome.reduce((a, b) => ({
        amount: parseInt(a.amount, 10) + parseInt(b.amount, 10),
      })).amount
      return `$${this.$options.filters.money(total)}`
    },
  },
  mounted() {
    // this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
    this.fetchGroupedExpensesWithExpenses({ projectId: this.$route.params.projectId })
  },
  methods: {
    ...mapActions('groupedExpenses', ['fetchGroupedExpenses']),
    ...mapActions('groupedExpenses', ['fetchGroupedExpensesWithExpenses']),
  },
}
</script>

<style></style>
